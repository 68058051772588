import React from "react";
import ReactDOM from "react-dom";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import { Provider } from "next-auth/client";
import { AuthProvider } from "../lib/useAuth";
import { SettingsProvider } from "../lib/useSettings";
import { UISettingsProvider } from "../lib/useUISettings";
import { ThemeProvider } from "styled-components";
import { StoreProvider } from "../store";

import PageChange from "components/PageChange/PageChange.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/nextjs-ncalc-dashboard.scss";

const theme = {
  colors: {
    primary: "#0070f3",
  },
};

Router.events.on("routeChangeStart", (url) => {
  document.body.classList.add("body-page-transition");
  ReactDOM.render(
    <PageChange path={url} />,
    document.getElementById("page-transition")
  );
});

Router.events.on("routeChangeComplete", (url) => {
  ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
  document.body.classList.remove("body-page-transition");
});

Router.events.on("routeChangeError", (url) => {
  ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
  document.body.classList.remove("body-page-transition");
});

const MyApp = (props) => {
  const { Component, pageProps } = props;
  const Layout = Component.layout || (({ children }) => <>{children}</>);

  return (
    <Provider session={pageProps.session}>
      <SettingsProvider>
        <UISettingsProvider>
          <AuthProvider>
            <React.Fragment>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>nCalc</title>
              </Head>

              <ThemeProvider theme={theme}>
                <StoreProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </StoreProvider>
              </ThemeProvider>
            </React.Fragment>
          </AuthProvider>
        </UISettingsProvider>
      </SettingsProvider>
    </Provider>
  );
};

export default MyApp;
